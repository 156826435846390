import React from "react";
import { Collapse } from "react-collapse";
import "./css/App.css";
import "./css/music-widget.css";

function ListenNowLink(props) {
  if (props.link && props.platform) {
    return (
      <a href={props.link}>
        <div className="listenNow-link">
          <span className="helper"></span>
          {props.platform !== "amazonMusic" ? (
            <img
              className={props.platform + "-logo"}
              src={process.env.PUBLIC_URL + "/logos/" + props.platform + ".svg"}
              alt={props.platform + " logo"}
              width="90px"
            />
          ) : (
            <img
              className={props.platform + "-logo"}
              src={process.env.PUBLIC_URL + "/logos/" + props.platform + ".jpg"}
              alt={props.platform + " logo"}
              width="120px"
            />
          )}
        </div>
      </a>
    );
  } else {
    return null;
  }
}

export default class MusicWidget extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpened: false };
  }

  render() {
    const { isOpened } = this.state;

    if (this.props.presave) {
      return (
        <div className="listenNow">
          <div className="listenNow-art">
            <img
              src={process.env.PUBLIC_URL + this.props.art}
              alt={this.props.art}
            />
          </div>

          <a href={this.props.presave} className="presave-link text-left">
            <div
              className="listenNow-toggler"
              onClick={() => this.setState({ isOpened: !this.state.isOpened })}
            >
              <h1>PRE-SAVE</h1>
            </div>
          </a>
        </div>
      );
    }

    return (
      <div className="listenNow">
        <div className="listenNow-art">
          <img
            src={process.env.PUBLIC_URL + this.props.art}
            alt={this.props.art}
          />
        </div>

        <div
          className="listenNow-toggler"
          onClick={() => this.setState({ isOpened: !this.state.isOpened })}
        >
          {}
          <h1>LISTEN NOW</h1>
          <img
            src={process.env.PUBLIC_URL + "/logos/expand.png"}
            className="expand-arrow"
            alt="expand"
          />
        </div>

        <Collapse isOpened={isOpened}>
          <section className="listenNow-links-container">
            <div>
              <ListenNowLink platform="spotify" link={this.props.spotifyLink} />
              <ListenNowLink platform="apple" link={this.props.appleLink} />
              <ListenNowLink
                platform="soundcloud"
                link={this.props.soundcloudLink}
              />
              <ListenNowLink platform="youtube" link={this.props.youtubeLink} />
              <ListenNowLink
                platform="youtubeMusic"
                link={this.props.youtubeMusicLink}
              />
              <ListenNowLink
                platform="amazonMusic"
                link={this.props.amazonLink}
              />
              <ListenNowLink platform="deezer" link={this.props.deezerLink} />
            </div>
          </section>
        </Collapse>
      </div>
    );
  }
}
