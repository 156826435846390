import React from "react";
import "./css/quote.css";
import { Container, Row, Col } from "reactstrap";

let Quote = () => {
  return (
    <Container fluid={true}>
      <Row className="no-gutters">
        <Col xs="12">
          <Row>
            <Col
              md={{ size: "10", offset: "1" }}
              xl={{ size: "6", offset: "3" }}
              className="bio-text px-md-5 px-4 py-5"
            >
              <div className="quote-card">
                <h1>
                  "Described as the 'Picasso of words,' he aims to craft
                  memorable bars."
                </h1>
                <a href="https://thepermanentrainpress.com/post/621828794950582272/the-wiseguy-fresco-domingo-review">
                  - the permanent rain press: "The Wiseguy: Fresco Domingo
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Quote;
