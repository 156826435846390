const videos = [
  {
    title: "ghostbumpz",
    link: "https://youtu.be/xFhhX3yVCW0?si=Dpn5o1pbDCQ-jD8C",
    embedded: "https://www.youtube-nocookie.com/embed/xFhhX3yVCW0",
    thumbnail: "/thumbnails/webp/ghostbumpz.webp",
  },
  {
    title: "BURNOUT",
    link: "https://youtu.be/UirSJCAF9yA?si=Dpn5o1pbDCQ-jD8C",
    embedded: "https://www.youtube-nocookie.com/embed/UirSJCAF9yA",
    thumbnail: "/thumbnails/burnout.jpg",
  },
  {
    title: "The Wiseguy x Aambrosiaa - Right Now",
    link: "https://www.youtube.com/watch?v=hgqaAoqCRHs",
    embedded: "https://www.youtube-nocookie.com/embed/hgqaAoqCRHs",
    thumbnail: "/thumbnails/right-now.jpg",
  },
  {
    title: "'Made 2 Stray' [REMIX]",
    link: "https://youtu.be/cg3M7iPhlYE",
    embedded: "https://www.youtube-nocookie.com/embed/cg3M7iPhlYE",
    thumbnail: "/thumbnails/made2stray.jpg",
  },
  {
    title: "Xavier Francis & The Wiseguy - eXotic",
    link: "https://youtu.be/HITHIsT7_5Q",
    embedded: "https://www.youtube-nocookie.com/embed/HITHIsT7_5Q",
    thumbnail: "/thumbnails/exotic.jpg",
  },
  {
    title: "OH NO (Remix)",
    link: "https://youtu.be/Z9lRp5lOYqA",
    embedded: "https://www.youtube-nocookie.com/embed/Z9lRp5lOYqA",
    thumbnail: "/thumbnails/ohno.jpg",
  },
  {
    title: "Ice Day",
    link: "https://youtu.be/brDX_KVHjAw",
    embedded: "https://www.youtube-nocookie.com/embed/brDX_KVHjAw",
    thumbnail: "/thumbnails/iceday.jpg",
  },
  {
    title: "ENORMOUS",
    link: "https://youtu.be/36_7FRRgXkY",
    embedded: "https://www.youtube-nocookie.com/embed/36_7FRRgXkY",
    thumbnail: "/thumbnails/enormous.jpg",
  },
  {
    title: "Xtendo",
    link: "https://youtu.be/xxhImALJl1k",
    embedded: "https://www.youtube-nocookie.com/embed/xxhImALJl1k",
    thumbnail: "/thumbnails/xtendo.jpg",
  },
  {
    title: "cookie pack",
    link: "https://youtu.be/vQfosGb3v-Q",
    embedded: "https://www.youtube-nocookie.com/embed/vQfosGb3v-Q",
    thumbnail: "/thumbnails/cookiepack.jpg",
  },
  {
    title: "ViNES EP",
    link: "https://youtu.be/6nQQ7sVU-og",
    embedded: "https://www.youtube-nocookie.com/embed/6nQQ7sVU-og",
    thumbnail: "/thumbnails/vines.jpg",
  },
  {
    title: "Burn Unit",
    link: "https://youtu.be/UX6iX6U8bQM",
    embedded: "https://www.youtube-nocookie.com/embed/UX6iX6U8bQM",
    thumbnail: "/thumbnails/burn-unit.jpg",
  },
  {
    title: "Rest Less",
    link: "https://youtu.be/UpMY2PbvDvs",
    embedded: "https://www.youtube-nocookie.com/embed/UpMY2PbvDvs",
    thumbnail: "/thumbnails/restless.jpg",
  },
  {
    title: "Oceans",
    link: "https://youtu.be/43m6oQe0vpQ",
    embedded: "https://www.youtube-nocookie.com/embed/43m6oQe0vpQ",
    thumbnail: "/thumbnails/oceans.jpg",
  },
  {
    title: "PaTcHeS",
    link: "https://youtu.be/c9zlSy2Tqz4",
    embedded: "https://www.youtube-nocookie.com/embed/c9zlSy2Tqz4",
    thumbnail: "/thumbnails/patches.jpg",
  },
];

export default videos;
