import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import "./css/newsletter.css";

const url =
  "https://wiseworldentertainment.us2.list-manage.com/subscribe/post?u=265a9063f2224d69a8471cfb5&amp;id=71b79d9ba3";

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div>
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="text-left input-group">
        <label htmlFor="email" className="d-inline">
          EMAIL
          <input
            ref={(node) => (email = node)}
            type="email"
            name="email"
            placeholder="Your email"
          />
        </label>
        <br />
      </div>
      <br />
      <br />
      <Button className="newsletter-button" onClick={submit}>
        Submit
      </Button>
    </div>
  );
};

// use the render prop and your custom form
const Newsletter = () => (
  <Container className="newsletter-section newsletter" fluid={true}>
    <Row className="h-100">
      <Col className="text-center my-auto">
        <div className="newsletter-wrapper">
          <h1>GET EMAIL UPDATES</h1>
          <h2>COMING SOON</h2>
          {/* <MailchimpSubscribe
            hidden
            url={url}
            className="newsletter"
            render={({ subscribe, status, message }) => (
              <div>
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              </div>
            )}
          /> */}
        </div>
      </Col>
    </Row>
  </Container>
);

export default Newsletter;
