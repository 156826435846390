import React, { useState } from "react";
import { Container, Row, Col, Modal, ModalBody } from "reactstrap";
import Carousel from "react-multi-carousel";
import useWindowDimensions from "./WindowHelpers";
import "react-multi-carousel/lib/styles.css";
import "./css/video-gallery.css";
import videos from "./videos.js";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

let VideoButton = (props) => {
  if (props.width < 800) {
    return (
      <div>
        <div className="videoTitle">
          <h3 style={{ color: "white" }}>{props.title}</h3>
        </div>
        <div>
          <a href={props.link}>
            <img
              src={props.thumbnail}
              className="thumbnail"
              alt={props.title}
            ></img>
          </a>
        </div>
      </div>
    );
  }
  return (
    <div
      onClick={() => {
        props.setVideo(props.embedded + "?rel=0&autoplay=1");
        props.toggle();
      }}
    >
      <div className="videoTitle">
        <h3 style={{ color: "white" }}>{props.title}</h3>
      </div>
      <img src={props.thumbnail} className="thumbnail" alt={props.title}></img>
    </div>
  );
};

const VideoGallery = () => {
  const [title, setTitle] = useState("restless");
  const [iframe, setVideo] = useState(
    "https://www.youtube.com/embed/xxhImALJl1k?rel=0&autoplay=1"
  );
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { height, width } = useWindowDimensions();

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="lg" className="modal-full">
        {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
        <ModalBody>
          <div className="">
            <div onClick={toggle} className="close-video">
              <span>X</span>
            </div>
          </div>
          <div className="video-container p-0">
            <iframe
              src={iframe}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </ModalBody>
      </Modal>
      <Container>
        <Row>
          <Col xl={{ size: 10, offset: 1 }}>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              // ssr={true} // means to render carousel on server-side.
              // infinite={true}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              transitionDuration={500}
              containerClass="carousel-container"
              // removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType="mobile"
              dotListClass="custom-dot-list-style"
              renderButtonGroupOutside={true}
              itemClass="carousel-item-padding-40-px"
            >
              <div>
                <Container>
                  <Row>
                    <Col className="text-center">
                      <VideoButton
                        link={videos[0]["link"]}
                        embedded={videos[0]["embedded"]}
                        thumbnail={videos[0]["thumbnail"]}
                        title={videos[0]["title"]}
                        setVideo={setVideo}
                        toggle={toggle}
                        width={width}
                      />
                      <br></br>
                      <VideoButton
                        link={videos[1]["link"]}
                        embedded={videos[1]["embedded"]}
                        thumbnail={videos[1]["thumbnail"]}
                        title={videos[1]["title"]}
                        setVideo={setVideo}
                        toggle={toggle}
                        width={width}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
              <div>
                <Container>
                  <Row>
                    <Col className="text-center">
                      <VideoButton
                        link={videos[2]["link"]}
                        embedded={videos[2]["embedded"]}
                        thumbnail={videos[2]["thumbnail"]}
                        title={videos[2]["title"]}
                        setVideo={setVideo}
                        toggle={toggle}
                        width={width}
                      />
                      <br></br>
                      <VideoButton
                        link={videos[3]["link"]}
                        embedded={videos[3]["embedded"]}
                        thumbnail={videos[3]["thumbnail"]}
                        title={videos[3]["title"]}
                        setVideo={setVideo}
                        toggle={toggle}
                        width={width}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
              <div>
                <Container>
                  <Container>
                    <Row>
                      <Col className="text-center">
                        <VideoButton
                          link={videos[4]["link"]}
                          embedded={videos[4]["embedded"]}
                          thumbnail={videos[4]["thumbnail"]}
                          title={videos[4]["title"]}
                          setVideo={setVideo}
                          toggle={toggle}
                          width={width}
                        />
                        <br></br>
                        <VideoButton
                          link={videos[5]["link"]}
                          embedded={videos[5]["embedded"]}
                          thumbnail={videos[5]["thumbnail"]}
                          title={videos[5]["title"]}
                          setVideo={setVideo}
                          toggle={toggle}
                          width={width}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
              <div>
                <Container>
                  <Container>
                    <Row>
                      <Col className="text-center">
                        <VideoButton
                          link={videos[6]["link"]}
                          embedded={videos[6]["embedded"]}
                          thumbnail={videos[6]["thumbnail"]}
                          title={videos[6]["title"]}
                          setVideo={setVideo}
                          toggle={toggle}
                          width={width}
                        />
                        <br></br>
                        <VideoButton
                          link={videos[7]["link"]}
                          embedded={videos[7]["embedded"]}
                          thumbnail={videos[7]["thumbnail"]}
                          title={videos[7]["title"]}
                          setVideo={setVideo}
                          toggle={toggle}
                          width={width}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
              <div>
                <Container>
                  <Container>
                    <Row>
                      <Col className="text-center">
                        <VideoButton
                          link={videos[8]["link"]}
                          embedded={videos[8]["embedded"]}
                          thumbnail={videos[8]["thumbnail"]}
                          title={videos[8]["title"]}
                          setVideo={setVideo}
                          toggle={toggle}
                          width={width}
                        />
                      </Col>
                      <Col className="text-center">
                        <VideoButton
                          link={videos[9]["link"]}
                          embedded={videos[9]["embedded"]}
                          thumbnail={videos[9]["thumbnail"]}
                          title={videos[9]["title"]}
                          setVideo={setVideo}
                          toggle={toggle}
                          width={width}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
              <div>
                <Container>
                  <Container>
                    <Row>
                      <Col className="text-center">
                        <VideoButton
                          link={videos[10]["link"]}
                          embedded={videos[10]["embedded"]}
                          thumbnail={videos[10]["thumbnail"]}
                          title={videos[10]["title"]}
                          setVideo={setVideo}
                          toggle={toggle}
                          width={width}
                        />
                      </Col>
                      <Col className="text-center">
                        <VideoButton
                          link={videos[11]["link"]}
                          embedded={videos[11]["embedded"]}
                          thumbnail={videos[11]["thumbnail"]}
                          title={videos[11]["title"]}
                          setVideo={setVideo}
                          toggle={toggle}
                          width={width}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
              <div>
                <Container>
                  <Container>
                    <Row>
                      <Col className="text-center">
                        <VideoButton
                          link={videos[12]["link"]}
                          embedded={videos[12]["embedded"]}
                          thumbnail={videos[12]["thumbnail"]}
                          title={videos[12]["title"]}
                          setVideo={setVideo}
                          toggle={toggle}
                          width={width}
                        />
                      </Col>
                      <Col className="text-center">
                        <VideoButton
                          link={videos[13]["link"]}
                          embedded={videos[13]["embedded"]}
                          thumbnail={videos[13]["thumbnail"]}
                          title={videos[13]["title"]}
                          setVideo={setVideo}
                          toggle={toggle}
                          width={width}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VideoGallery;
