import React from "react";
import "./css/App.css";
import { Container, Row, Col } from "reactstrap";
import Navbar from "./Navbar";
import MusicWidget from "./MusicWidget";
import VideoGallery from "./VideoGallery";
import Bio from "./Bio";
import Quote from "./Quote.js";
import Contact from "./Contact.js";
import Newsletter from "./Newsletter";

function App() {
  return (
    <div className="App text-white">
      {/* <video autoPlay={true} muted loop id="myVideo">
        <source src="videoplayback.webm" 
        type="video/mp4"></source>
      </video> */}
      {/* <Header></Header> */}
      <Navbar></Navbar>
      <Container name="music" className="px-0 text-center">
        <Row className="no-gutters">
          <Col className="text-center">
            <h1 className="mt-5 glow">MUSIC</h1>
          </Col>
        </Row>
        <Row className="music no-gutters pt-2 pt-md-5">
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/ghostbumpz.webp"
              spotifyLink="https://open.spotify.com/album/6gGl7Va0jvPT6MlTXusysM?referral=labelaffiliate&utm_source=1011lypr7ixN&utm_medium=Indie_CDBaby&utm_campaign=labelaffiliate"
              appleLink="https://music.apple.com/ca/album/ghostbumpz-feat-aambrosiaa-xavier-francis-single/1728116851?at=1000lM6c&uo=4"
              youtubeLink="https://www.youtube.com/watch?v=xFhhX3yVCW0"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/burnout.webp"
              spotifyLink="https://open.spotify.com/track/6MXAr6bXbtpJUkKXfcLhsx?utm_medium=share&utm_source=linktree"
              appleLink="https://music.apple.com/ca/album/burnout-feat-ben-chemikal-single/1723992565"
              youtubeLink="https://www.youtube.com/watch?v=KwgdkMApMT8"
              soundcloudLink="https://soundcloud.com/thewisestguy/burnout-prod-ben-chemikal"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/funk-in-the-pm.jpg"
              spotifyLink="https://open.spotify.com/track/19j5UGB44aivJp9R23XUXk?si=e441cd7322f64ed9"
              appleLink="https://music.apple.com/ca/album/funk-in-the-pm-single/1687807414"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/junglejam.jpg"
              spotifyLink="https://open.spotify.com/track/3N1HaigNcoqjriCsRShR1c?si=e200ebeb43e54ca8"
              appleLink="https://music.apple.com/ca/album/jungle-jam-single/1685606156"
              youtubeLink="https://youtu.be/5KiDPpAJJ3U?si=b17B1EvKiE_2nzzg"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/right-now.jpg"
              spotifyLink="https://open.spotify.com/track/4bgLuqhdU4qFTNk7ERPuDE?si=2e2a731c85ef4ca7"
              appleLink="https://music.apple.com/ca/album/right-now-feat-aambrosiaa-prodfuji/1672850948?i=1672850949"
              youtubeLink="https://youtu.be/nH1ItzMWXm8"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/exotic.jpg"
              spotifyLink="https://open.spotify.com/album/1D4FxJb2Z9TzUdup0ceIgn?si=hR_bVpWkQOCcdGaqbNhIPg"
              appleLink="https://music.apple.com/ca/album/exotic-single/1594573928"
              youtubeLink="https://youtu.be/HITHIsT7_5Q"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/poonah.jpg"
              spotifyLink="https://open.spotify.com/album/39tBmxu5O0wD4aXEiehlOm?si=q1RZBufHQt-knT6NPDQknw&dl_branch=1"
              soundcloudLink="https://soundcloud.com/thewisestguy/poonah-prod-felix-beatz"
              appleLink="https://music.apple.com/ca/album/poonah-single/1577187455"
              youtubeLink="https://youtu.be/l_RS9n1m5-w"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/paper-route.jpeg"
              spotifyLink="https://open.spotify.com/album/24PbX2q1ExBapmTlxXIxaG?si=OlaBvQkORM23OM-wcpvZ9A&dl_branch=1"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/break_outta_bc.jpeg"
              spotifyLink="https://open.spotify.com/album/5P0ZDknzwKjxhmCNZSeRNc?si=lAnND_s5TdmZSPVJMtcvMg&dl_branch=1"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/off-track.jpg"
              spotifyLink="https://open.spotify.com/album/7DEDWif8qG3O2wLa0WjV7g"
              appleLink="https://music.apple.com/us/album/off-track-feat-flavour-houdini-single/1554151047?uo=4"
              amazonLink="https://www.amazon.com/gp/product/B08WTTMCC3"
              deezerLink="https://www.deezer.com/us/album/208499382"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/aquadip.jpg"
              spotifyLink="https://open.spotify.com/track/5zKDkWQYndAemHTTjxdrr1?si=DS8szCgSQlSbJn5mpzYb6w"
              appleLink="https://music.apple.com/us/album/ice-day-feat-flavour-houdini-single/1532786908"
              youtubeLink="https://youtu.be/6ClIRBnD0ws"
              soundcloudLink="https://soundcloud.com/thewisestguy/aquadip"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/ice-day.jpg"
              spotifyLink="https://open.spotify.com/album/1PMTbvQrpCyg1pmhZlRKy9"
              appleLink="https://music.apple.com/us/album/ice-day-feat-flavour-houdini-single/1532786908?uo=4"
              youtubeLink="https://www.youtube.com/watch?v=o3N7UWTbnmY&feature=youtu.be"
              soundcloudLink="https://soundcloud.com/thewisestguy/ice-day-ft-flavour-houdini"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/enormous.jpg"
              spotifyLink="https://open.spotify.com/track/3nBIaTQ4eYsdRskYFkab2n?si=tZY7qFjNScqURzVv1fDAIA"
              appleLink="https://music.apple.com/ca/album/xtendo-feat-xavier-francis-flavour-houdini-single/1514724052"
              youtubeLink="https://youtu.be/36_7FRRgXkY"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/xtendo.jpg"
              spotifyLink="https://open.spotify.com/track/3nBIaTQ4eYsdRskYFkab2n?si=tZY7qFjNScqURzVv1fDAIA"
              appleLink="https://music.apple.com/ca/album/xtendo-feat-xavier-francis-flavour-houdini-single/1514724052"
              soundcloudLink="https://soundcloud.com/thewisestguy/xtendo-ft-xavier-francis-flavour-houdini"
              youtubeLink="https://youtu.be/xxhImALJl1k"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl={{ size: "3" }}>
            <MusicWidget
              art="/art/fresco-domingo.jpg"
              spotifyLink="https://open.spotify.com/album/4mkquv9EqF3O90kjB3xAI2?si=Q-NscA4eRoSfvCBhHXS6xA"
              appleLink="https://music.apple.com/ca/album/fresco-domingo/1493120892"
              soundcloudLink="https://soundcloud.com/thewisestguy/sets/fresco-domingo"
              youtubeLink="https://www.youtube.com/watch?v=8kitQlDCJSE&list=OLAK5uy_lc_JXsw2arxxBJbWgFDJ2gSrtViW6az1M"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl="3">
            <MusicWidget
              art="/art/cookie-pack.jpg"
              spotifyLink="https://open.spotify.com/track/1nfusqRuk3d2YolOXjr7Ph?si=ciLP3PA0R1ugbnfGKJOJJg"
              appleLink="https://music.apple.com/ca/album/cookie-pack-single/1487569453"
              soundcloudLink="https://soundcloud.com/thewisestguy/cookie-pack"
              youtubeLink="https://www.youtube.com/watch?v=vQfosGb3v-Q"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl="3">
            <MusicWidget
              art="/art/rest-less.jpg"
              spotifyLink="https://open.spotify.com/track/0fL4YSMZ3IC060niZOqMb4?si=shuFKUK5TtWdbnQoSSYGHw"
              appleLink="https://music.apple.com/ca/album/rest-less-single/1482973992"
              soundcloudLink="https://soundcloud.com/thewisestguy/rest-less"
              youtubeLink="https://www.youtube.com/watch?v=DeWfJ9AliIs"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl="3">
            <MusicWidget
              art="/art/burn-unit.jpg"
              spotifyLink="https://open.spotify.com/track/40RepeNjlcjCzUjplPJXkr?si=BFjf6AtITjqVdd0Omh3wpA"
              appleLink="https://music.apple.com/ca/album/burn-unit-single/1484922531"
              soundcloudLink="https://soundcloud.com/thewisestguy/burn-unit"
              youtubeLink="https://youtu.be/UX6iX6U8bQM"
            />
          </Col>
          <Col xs="12" md="6" lg="4" xl="3">
            <MusicWidget
              art="/art/rash-decisions.jpg"
              spotifyLink="https://open.spotify.com/track/6C5fZEXMjrDSQAlEmhcBAm"
              soundcloudLink="https://soundcloud.com/thewisestguy/rash-decision-ft-nox"
            />
          </Col>
        </Row>
      </Container>
      <div style={{ height: "20vh" }}></div>
      <Container fluid={true} className="px-0" name="videos">
        <Row className="no-gutters">
          <Col className="text-center mb-3">
            <h1 className="my-5 glow">VIDEOS</h1>
          </Col>
        </Row>
        <VideoGallery></VideoGallery>
      </Container>
      <div style={{ height: "30vh" }}></div>
      <Quote></Quote>
      <div style={{ height: "30vh" }}></div>
      <Contact></Contact>
      <div style={{ height: "30vh" }}></div>
      <Newsletter name="newsletter"></Newsletter>
    </div>
  );
}

export default App;
