import React from "react";
import "./css/contact.css";
import { Container, Row, Col } from "reactstrap";

let Contact = () => {
  return (
    <Container fluid={true}>
      <Row className="no-gutters">
        <Col xs="12">
          <Row>
            <Col
              md={{ size: "10", offset: "1" }}
              xl={{ size: "4", offset: "4" }}
              className="bio-text px-md-5 px-4 py-5"
            >
              <div className="quote-card text-center">
                <div>
                  <h2>Contact</h2>
                  <a
                    className="contact-email"
                    href="mailto:info@wiseworldentertainment.com"
                  >
                    info@wiseworldentertainment.com
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
