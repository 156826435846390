import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./css/bio.css";

class Bio extends Component {
  render() {
    return (
      <div className="bio">
        <Row className="no-gutters">
          <Col
            md={{ size: "10", offset: "1" }}
            xl={{ size: "6", offset: "3" }}
            className="bio-text px-md-5 px-4 py-5"
          >
            <p>
              I have been an independent hip-hop artist/musician/promoter &
              creative based on Vancouver Island since 2014. With closeted
              dreams of gripping the mic in front of a stadium crowd since I was
              single digits, the unforeseen circumstances of becoming a
              Vancouver Island underground phenomenon seemed like a fairy tale
              at first. With demo discs floating in hallways, to making
              appearances at open mic’s, backyard parties and basement shows in
              my senior year of highschool, was when the name first began to
              circulate.
            </p>
            <p>
              With mixtape and EP releases (PaTcHeS [2016] & The Vines EP
              [2018]), a consistent evolution of unique music videos, a couple
              BC tours under my belt, bar/hall rental show success’s and flops,
              triumphs and failures with stepping into the streaming era, from
              working in a skid row bar all night to working in an overdose
              prevention site by day transitioning into running an underground
              performance venue/recording studio since late 2018 by night, I
              feel as if these four years have prepared my mind body and soul
              for the next steps towards my dreams of gripping that mic in front
              of a stadium crowd.
            </p>
            <p>
              Working closely alongside longtime friend and collaborator, The
              Flavour Houdini, and I have created a prolific catalogue of high
              energy listens. With Houdini’s carefully calculated beats its
              paired perfectly with wise words, cunning wordplay, ear catching
              melodies, and rather memorable bars to bring you songs like “Rash
              Decisions”, “Cookie Pack”, “Enormous and more!
            </p>
            <p>
              Often labelled "the Picasso of words", and the Bob Dylan of rap,
              with unpredictably high on stage energy inspired by Jim Morrison,
              the old soul that lives in me wears its entire life experience on
              my lyrical sleeve.
            </p>
            <p>
              I try to offer the best of both worlds. I started as a backpack
              rapper but have found my sound evolve to somewhere in between new
              and old. The Lo-Fi word smithery paired with all of the autotuned
              charisma, essence and energy of a new school/Soundcloud rapper,
              alongside the spacey effected harmonica on some tracks that makes
              for a listen like nothing ever heard in hiphop before.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Bio;
