import React, { useState } from "react";
import { Link } from "react-scroll";
import { Container, Row, Col } from "reactstrap";
import "./css/navbar.css";

let DesktopNavbar = () => {
  return (
    <div id="navbar" className="d-none d-md-block">
      <Link
        activeClass="active"
        to="music"
        spy={true}
        smooth={true}
        duration={900}
        className="ml-50"
        href="#"
      >
        Music
      </Link>
      <Link
        activeClass="active"
        to="videos"
        spy={true}
        smooth={true}
        duration={900}
        className="ml-50"
        href="#"
      >
        Videos
      </Link>
      <Link
        activeClass="active"
        to="newsletter"
        spy={true}
        smooth={true}
        duration={1100}
        href="#"
      >
        Subscribe
      </Link>
      <a
        href="https://wiseworld.bigcartel.com/products"
        target="_blank"
        rel="noopener noreferrer"
      >
        Merch
      </a>
      <div className="float-right d-none d-sm-none d-md-block nav-socials mr-4">
        <a
          href="https://www.instagram.com/wiseyguy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-instagram"></i>
        </a>
        <a
          href="https://www.facebook.com/thewisestguy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-facebook"></i>
        </a>
        <a
          href="https://open.spotify.com/artist/4uZMukdzKUX3T35hozbTXw?si=H9-sM0ucTnGIgfRuKdlyQQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-spotify"></i>
        </a>
        <a
          href="https://www.youtube.com/channel/UC1uDj_K4LEslF0E3_mW8TGg/featured"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-youtube"></i>
        </a>
        <a
          href="https://soundcloud.com/thewisestguy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-soundcloud"></i>
        </a>
        <a
          href="https://music.apple.com/ca/artist/the-wiseguy/1476668042"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-apple"></i>
        </a>
      </div>
    </div>
  );
};

let Navbar = () => {
  const [socialsOpen, setSocialsOpen] = useState(false);
  const [navOpened, setNavOpened] = useState(false);

  return (
    <div className="mobile-nav text-right">
      <div className="d-block d-md-none">
        <i
          className="fa fa-share-alt mr-4"
          onClick={() => {
            setSocialsOpen(true);
            document.body.style.overflow = "hidden";
          }}
        ></i>
        <i
          className="fa fa-bars mr-3"
          onClick={() => {
            setNavOpened(true);
            document.body.style.overflow = "hidden";
          }}
        ></i>
        <div className={socialsOpen ? "overlay w-100" : "d-none"}>
          <Container className="overlay-content socials-overlay" fluid={true}>
            <Row>
              <Col className="text-right">
                <i
                  className="fa fa-close mr-3"
                  onClick={() => {
                    setSocialsOpen(false);
                    document.body.style.overflow = "scroll";
                  }}
                ></i>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <a
                  href="https://www.instagram.com/wiseyguy/"
                  className="nav__socials-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>
                <a
                  href="https://www.facebook.com/thewisestguy/"
                  className="nav__socials-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook"></i>
                </a>
                <a
                  href="https://open.spotify.com/artist/4uZMukdzKUX3T35hozbTXw?si=H9-sM0ucTnGIgfRuKdlyQQ"
                  className="nav__socials-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-spotify"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UC1uDj_K4LEslF0E3_mW8TGg/featured"
                  className="nav__socials-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-youtube"></i>
                </a>
                <a
                  href="https://soundcloud.com/thewisestguy"
                  className="nav__socials-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-soundcloud"></i>
                </a>
                <a
                  href="https://music.apple.com/ca/artist/the-wiseguy/1476668042"
                  className="nav__socials-button mb-5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-apple"></i>
                </a>
                <div className="socials__contact">
                  <h4>Contact</h4>
                  <h5>info@wiseworldentertainment.com</h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={navOpened ? "overlay w-100" : "d-none"}>
          <Container className="overlay-content">
            <Row>
              <Col className="text-right">
                <span
                  className="fa fa-close"
                  onClick={() => {
                    setNavOpened(false);
                    document.body.style.overflow = "scroll";
                  }}
                ></span>
              </Col>
            </Row>
            <Row>
              <Col className="text-center pt-5">
                <Link
                  activeClass="active"
                  to="music"
                  spy={true}
                  smooth={true}
                  duration={750}
                  className="mobile-nav__link"
                  href="#"
                  onClick={() => {
                    setNavOpened(false);
                    document.body.style.overflow = "scroll";
                  }}
                >
                  Music
                </Link>
                <br></br>
                <Link
                  activeClass="active"
                  to="videos"
                  spy={true}
                  smooth={true}
                  duration={750}
                  className="mobile-nav__link"
                  href="#"
                  onClick={() => {
                    setNavOpened(false);
                    document.body.style.overflow = "scroll";
                  }}
                >
                  Videos
                </Link>
                <br></br>
                <Link
                  activeClass="active"
                  to="newsletter"
                  spy={true}
                  smooth={true}
                  duration={750}
                  className="mobile-nav__link"
                  href="#"
                  onClick={() => {
                    setNavOpened(false);
                    document.body.style.overflow = "scroll";
                  }}
                >
                  Subscribe
                </Link>
                <br></br>
                <a
                  href="https://wiseworld.bigcartel.com/products"
                  className="mobile-nav__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Merch
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DesktopNavbar></DesktopNavbar>
    </div>
  );
};

export default Navbar;
